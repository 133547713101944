.support {
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 25px;
  padding-bottom: 0;
  height: calc(100% - 105px);
  overflow-y: auto;
}
@media screen and (max-width: 700px) {
  .support {
    padding: 25px 10px;
    width: calc(100% - 20px);
  }
}
