.login {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100dvh;
  width: 100%;
  background-color: #f2f5fd;
  overflow: hidden;
  font-family: Nunito;
}

.loginPage_BG {
  position: absolute;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 70vw;
  object-fit: cover;
}

.form_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100dvh;
  /* min-height: 100dvh; */
  /* overflow-y: auto; */
}

.logo {
  width: 50%;
}

.logo img {
  max-height: 320px;
}

.form_section {
  width: 60%;
  height: 150%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #f2f5fd;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  overflow: hidden;
  /* max-width: 400px; */
}

.form {
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.form_title {
  font-size: 30px;
  font-weight: 600;
}

.sign_up {
  text-align: left;
}

.form_title img {
  cursor: pointer;
}

.form_components {
  display: flex;
  flex-direction: column;
}

.form_component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* margin: 0 10px ; */
  gap: 10px;
  margin-bottom: 20px;
}

.form_component .title {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  color: #515357;
}

.form_component .input_container {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  align-items: center;

  border: 1px solid #000000;
}

.form_component .input_container input {
  width: 100%;
  min-width: 300px;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  background-color: #ffffff;
  border-radius: 6px;
  padding-left: 60px;
  /* text-align: center; */
}

.form_component .input_container input.sign_up_input {
  padding-left: 15px;
}

.forgot_password_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 10px;
}

.forgot_password {
  color: #5981e9;
  text-decoration: none;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.signup_wrap {
  display: flex;
  justify-content: center;
  gap: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.success_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.success_wrap p {
  color: var(--neutral-neutral-01, #000);
  font-family: Nunito;
  font-size: 16px;

  font-weight: 500;
}

.success {
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 1200px) {
  .form_component .input_container input {
    min-width: 200px;
  }

  .forgot_password_wrap {
    width: calc(100% - 20px);
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.form_component .input_container input.password_input {
  padding-right: 60px;
}

.input_container img {
  width: 30px;
  height: 31px;
  padding: 10px;
  position: absolute;

  /* border-right: #000000 1px solid; */
  border-left: #000000 1px solid;
  right: 0px;
}

.input_container img:nth-child(1) {
  border-left: none;
  border-right: #000000 1px solid;
  left: 0px;
}

.input_container_error {
  border-color: #ff0000 !important;
  background: #fff0f0 !important;
  color: #ff0000 !important;
}

.input_container_error img {
  border-color: #ff0000 !important;
}

.password_reveal {
  cursor: pointer;
}

.submit_button {
  width: 100%;
  height: 50px;
  margin: 20px 0;
  border: none;
  border-radius: 6px;
  background-color: #5981e9;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.error_msg {
  color: #e80000;
  font-size: 16px;
  font-weight: 500;

  margin-top: -10px;
  margin-bottom: -10px;

  max-width: 350px;
  align-self: center;
}

.additional_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.additional_info > div,
span {
  color: #696f79;
}

.highlight {
  /* color: #5981e9; */
  cursor: pointer;
}

/**Otp Authentication**/

.otp_container {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  /* justify-content: center; */
  align-items: center;
  /* gap: 10px; */
}

.otp_container input {
  width: 40px;
  height: 40px;
  border: 1px solid #000000;
  border-radius: 6px;
  outline: none;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.otp_container input::-webkit-outer-spin-button,
.otp_container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.otp_container input[type="number"] {
  -moz-appearance: textfield;
}

.input_container input::placeholder {
  font-weight: 400 !important;
  color: #696f79;
}

@media screen and (max-width: 1000px) {
  .login {
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: auto;
    overflow-y: auto;
  }
  .form_title {
    font-size: 25px;
  }

  .loginPage_BG {
    display: none;
  }

  .form {
    width: 90%;
    gap: 10px;
  }

  .form_container {
    flex-direction: column;
    /* gap: 20px; */
    /* height: -webkit-fill-available; */
  }

  .form_component .input_container {
    width: 97%;
  }

  .form_component .input_container input {
    min-width: 150px;
  }

  .logo {
    width: max-content;
    height: max-content;
    /* margin-top: 20px; */
  }

  .logo img {
    max-width: 130px;
    /* height: 100px; */
  }

  .form_section {
    width: 100%;
    height: max-content;
    border-radius: 0;
    /* justify-content: flex-start; */
  }
}

.form_hint {
  font-size: 13px;
  font-weight: 500;
  color: #696f79;
  /* margin-top: -10px; */
  /* margin-bottom: -10px; */
  max-width: 360px;

  align-self: center;
}

.back_button {
  cursor: pointer;
  color: #5981e9;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  margin-bottom: -20px;
}

@media screen and (max-width: 1000px) {
  .back_button {
    margin-top: 10px;
    margin-bottom: -10px;
  }
}

.error_message {
  color: #ff0000;
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  margin-top: -10px;
  max-width: 370px;
}

.success_modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
}

.success_modal {
  width: 400px;
  height: 400px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .success_modal {
    width: 80%;
  }
}

.circle_loader {
  margin-bottom: 20px;
  border: 3px solid #67a486;
  /* animation: loader-spin 1.2s infinite linear; */
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 94px;
  height: 94px;
}

.checkmark {
  /* display: none; */

  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 0;
    height: 50px;
    width: 25px;
    transform-origin: left top;
    border-right: 3px solid #67a486;
    border-top: 3px solid #67a486;
    content: "";
    left: calc((100px / 6) + (100px / 12));
    top: calc(100px / 2);
    position: absolute;
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: calc((100px / 2) / 2);
    opacity: 1;
  }
  40% {
    height: calc(100px / 2);
    width: calc((100px / 2) / 2);
    opacity: 1;
  }
  100% {
    height: calc(100px / 2);
    width: calc((100px / 2) / 2);
    opacity: 0;
  }
}

/* show approval_icon after checkmark animation completes and hide check*/

.approval_icon {
  width: 100px;
  height: 100px;
  position: relative;
  top: -3px;
  left: -3px;
  animation-duration: 1000ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-name: showFace;
}

@keyframes showFace {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.success_modal_title {
  font-family: Nunito;
  font-size: 30px;
  font-weight: 700;
  text-align: left;
}

.success_modal_message {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.success_modal_button {
  width: 100px;
  height: 40px;
  border-radius: 6px;
  background-color: #67a486;

  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  margin-top: 30px;

  cursor: pointer;
}
