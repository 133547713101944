.tenants_invoice_warapper > * {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
  color-adjust: exact !important; /* Firefox 48 – 96 */
  print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
}

.tenants_invoice_warapper {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* display: none; */

  width: 100%;
  height: calc(100dvh - 80px);
  overflow-y: auto;
}

.tenants_invoice_warapper {
  min-width: 1050px;
}

.page_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.row_action {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 20px;
  gap: 20px;
  float: right;
}

.row_action div {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.page_action img {
  cursor: pointer;
}

.tenants_invoice {
  background-color: #ffffff;
  height: min-content;
  /* overflow: hidden; */
  position: relative;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.mid_title_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  /* margin-bottom: 15px; */
}
.mid_title_container .title1 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.mid_title_container .title2 {
}
.invoice_details {
  width: calc(100% - 40px);
  display: flex;
  gap: 50px;
  padding: 20px;
}

.invoice_details > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;

  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.data_summary {
  background-color: #5981e9 !important;
}

.data_summary td:nth-child(1) {
  text-align: start;
}

.data_summary td {
  color: #ffffff;
  font-size: 16px !important;
}
.invoice_details .left_details {
  /* flex: 2; */
}

/* .invoice_details .right_details {
  align-items: flex-end;
} */

.invoice_details .right_details div,
.invoice_details .left_details div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invoice_details input[type="text"],
.invoice_details input[type="date"] {
  outline: none;
  border: none;
  border-bottom: 1px solid #9a9595;
  /* width: 100%; */
  min-width: 300px;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  padding: 5px;
  box-sizing: border-box;
}

.invoice_body {
  width: 100%;
  display: flex;
  justify-content: center;
}
.invoice_data_table {
  width: calc(100% - 40px);
}
/* .invoice_data_table tr {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.invoice_data_table,
th,
td {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* font-size: 20px; */
}

.invoice_data_table {
  /* margin-bottom: 10px; */
  /* border-spacing: 0; */
  /* border-collapse: collapse; */
}

.invoice_data_table th {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: center;
}

.invoice_data_table td,
th {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px 0px;
  position: relative;
}

.invoice_data_table input,
.invoice_data_table input[type="date"],
.invoice_data_table input[type="number"],
.invoice_data_table input[type="text"] {
  outline: none;
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  max-width: 100%; /* Set max-width to 100% */
  box-sizing: border-box; /* Ensure padding is included in the width */
  min-width: none;
}
/* alternate background color*/

.invoice_data_table tr:nth-child(even) {
  background-color: rgba(203, 231, 249, 0.2);
}

.invoice_data_table tr:nth-child(odd) {
  background-color: #ffffff;
}

.invoice_footer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  position: fixed;
  bottom: 0px;
  margin-top: 50px;
  display: none;
}
@media print {
  @page {
    size: A4;
    /* margin: 0; */
  }

  html,
  body {
    width: 210mm;
    /* height: calc(100% - 200px); */
    /* height: 100%; */
  }

  .invoice_footer {
    /* position: absolute; */
    position: fixed;
    display: block;

    /* page-break-before: always; */
  }
  .invoice_data_table tr:nth-child(21) {
    page-break-after: always;
  }
}

.invoice_footer p {
  margin: 0px;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #9a9595;
}

.tenants_invoice_warapper_fixed input[type="text"],
.tenants_invoice_warapper_fixed input[type="date"],
.tenants_invoice_warapper_fixed input[type="number"] {
  outline: none;
  border: none;
}

.child_centre {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media print {
  .rectangle {
    background-color: #5981e9;
  }
} */
