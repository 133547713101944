.view_details {
  height: calc(100% - 240px);
  overflow-x: auto;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
  padding: 20px;
}

.header_wrap {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .header_wrap {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.header_button {
  display: flex;
  gap: 20px;
}

.title {
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--neutral-neutral-01, #000);
  font-size: 18px;
  font-weight: 500;
}

.title img {
  width: 27.001px;
  height: 15.935px;
  cursor: pointer;
}

.mid_table_title {
  margin-top: 20px;
}
