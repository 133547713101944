.update_form {
  display: flex;
  flex-direction: column;
}

.profile_update_form {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin: 20px;
}

.input_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  /* margin-right: 100px; */
}

.input_name {
  color: var(--neutral-neutral-01, #000);
  font-size: 16px;
  font-weight: 400;
}

.field {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  border-radius: 10px;
  border: 1px solid #97ade7;
  background: rgba(89, 129, 233, 0.1);
  color: #5981e9;
  font-size: 18px;
  font-weight: 500;
  outline: none;
}

.field_error {
  border: 1px solid #ff0000;
  background: #fff0f0;
  color: #ff0000;
}

.input_field {
  /* min-width: 452px; */
  min-width: 240px;
  height: 48px;
}

.error_message {
  color: #ff0000;
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  margin-top: -10px;
}
.input_field::placeholder {
  color: var(--Neutral-Neutral-01, #000);
  font-weight: 400;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::file-selector-button {
  display: none;
}

.upload_button {
  min-width: 432px;
  cursor: pointer;
  padding: 12px 20px;
}

.address_field {
  min-width: 452px;
  height: 164px;
}

.form_submit_button {
  width: 234px;
  height: 48px;
  border-radius: 3.636px;
  background: #5981e9;
  border: none;
  color: #fff;
  margin-left: 35%;
}

@media (max-width: 1300px) {
  .form_submit_button {
    margin-left: 2%;
  }
}

/**Form Modal***/

.form_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 50%);
}

.form_container {
  display: flex;
  flex-direction: column;
  height: max-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  gap: 20px;
  width: 600px;
}

.form_container .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form_container .header .title {
  font-size: 20px;
  font-weight: 500;
}

.form_container .header .close_handler {
  cursor: pointer;
  background-color: #fff;
  color: #5981e9;
  font-size: large;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
}

.form_container .footer {
  display: flex;
  justify-content: center;
}

.form__components {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .form_container {
    justify-content: center;
  }
}
