.admin_tenants_content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  /* Header - 80px, Padding top - 25px */
  height: calc(100% - 105px);
  padding: 25px;
  gap: 20px;
  padding-bottom: 0px;
}

@media screen and (max-width: 700px) {
  .admin_tenants_content {
    padding: 10px;
    width: calc(100% - 20px);
  }
}

.tenants_stats_table_actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  gap: 10px 40px;

  flex-wrap: wrap;
}

.rentStatusPaid,
.rentStatusPending {
  margin: 0px;
  /* font-size: larger; */
  font-weight: 500;
}

.rentStatusPaid {
  color: #00b428;
}

.rentStatusPending {
  color: #e80000;
}

.table_checkbox {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
}

.sendMsg_container_wrapper {
  display: flex;
  width: calc(100% - 60px);
  /* height: 150px; */
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #5981e9;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
}

.sendMsg_container_right {
  display: flex;
  gap: 20px;
  /* height: 50px; */
  align-items: center;
}
.sendMsg_container_left p {
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
}

.sendMsg_container_wrapper button {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.schedule_button {
  color: #5981e9;
  background-color: white;
  padding: 8px;
  border: 1px solid #5981e9;
  outline: none;
  border-radius: 5px;
}
.send_now_button {
  color: white;
  background-color: #5981e9;
  padding: 8px;
  border: 1px solid #5981e9;
  outline: none;
  border-radius: 5px;
}

.filter_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}

.filter_section img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.filter_popup_container {
  position: absolute;
  top: 40px;
  right: 0px;
  width: 170px;
  /* height: 300px; */
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1000;
}
.filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filters p {
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  color: #534d4a;
  text-align: left;
}

.filter {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.filter input {
  /* width: 100%; */
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0px 10px;
}

.form_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 50%);
}

.form_container {
  display: flex;
  flex-direction: column;
  height: max-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  gap: 20px;
  width: 450px;
}

.form_container .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form_container .header .title {
  font-size: 20px;
  font-weight: 500;
}

.form_container .header .close_handler {
  cursor: pointer;
  background-color: #fff;
  color: #5981e9;
  font-size: large;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
}

.form_container .footer {
  display: flex;
  justify-content: center;
}

.form__components {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.template_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.template_content p {
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #534d4a;
  padding-left: 10px;
}
.template_content_header,
.template_content_footer {
  text-align: left;
}

.form__components textarea {
  height: 200px;
  border-radius: 5px;
  border: 1px solid #5981e980;
  padding: 10px;
  outline: none;
}

.form_row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.form_row div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* flex: 1; */
  gap: 5px;
}

.form_row div p {
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
}

.form_row div input {
  height: 30px;
  border-radius: 5px;
  border: 1px solid #5981e980;
  padding: 0px 10px;
  outline: none;
}
