.tenants_table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 97%;
}

.table_header {
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--neutral-neutral-01, #000);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.table_header img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.content_wrap {
  display: flex;
  gap: 18%;
  width: calc(100% - 30px);
  border-radius: 10px;
  padding: 10px;
  flex-wrap: wrap;
}

.content_wrap_title {
  color: var(--neutral-neutral-01, #000);
  font-size: 16px;
  font-weight: 500;
  min-width: 200px;
  text-align: left;
}

.content_wrap_value {
  color: var(--neutral-neutral-01, #000);
  font-size: 16px;
}

.tenants_table div:nth-child(even) {
  background-color: rgba(203, 231, 249, 0.2);
}

@media (max-width: 600px) {
  .content_wrap span {
    font-size: 14px;
  }
}
