.status_button {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 157px;
  padding: 7px 20px;
  border-radius: 3.636px;
  border: 1px solid #67a486;
}

.horizontal_line {
  width: 18.028px;
  height: 0px;
  transform: rotate(-90deg);
  border: 1px solid #67a486;
}

.declined {
  border: 1px solid #e80000;
  color: #e80000;
}

.waiting {
  border: 1px solid #6778a4;
  color: #6778a4;
}

.button_text {
  min-width: 92.892px;
  color: #67a486;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
}

.button_text_declined {
  color: #e80000;
}

.button_text_waiting {
  color: #6778a4;
}

