.admin_parking_content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  /* Header - 80px, Padding top - 25px */
  height: calc(100% - 105px);
  padding: 25px;
  gap: 20px;
  padding-bottom: 0px;
}

.parking_stats_table_actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  gap: 10px 40px;

  flex-wrap: wrap;
}

/**Form Modal***/

.form_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 50%);
}

.form_container {
  display: flex;
  flex-direction: column;
  height: max-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  gap: 20px;
  width: 400px;
}

.form_container .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form_container .header .title {
  font-size: 20px;
  font-weight: 500;
}

.form_container .header .close_handler {
  cursor: pointer;
  background-color: #fff;
  color: #5981e9;
  font-size: large;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
}

.form_components {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.form_components .form_component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.form_components .form_component label {
  font-size: 14px;
  font-weight: 500;
}

.form_components .form_component input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.form_container .footer {
  display: flex;
  justify-content: center;
}
