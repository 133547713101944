.request_history {
  display: flex;
  flex-direction: column;
  margin: 25px;
  padding: 25px;
  margin-bottom: 0;
  /* gap: 25px; */
  height: -webkit-fill-available;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
  border-radius: 10px;
}

@media screen and (min-width: 1000px) {
  .request_history {
    overflow-y: auto;
  }
}

@media screen and (max-width: 700px) {
  .request_history {
    margin: 25px 10px;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.header span {
  border-bottom: 5px solid rgba(89, 129, 233, 0.8);
  padding-bottom: 5px;
  color: var(--Neutral-Neutral-01, #000);
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
}

.new_request {
  width: 187px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #5981e9;
  background: #5981e9;
  color: var(--Neutral-Neutral-02, #fff);
  font-family: Nunito;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: none;
}

@media (max-width: 1000px) {
  .header {
    gap: 20px;
  }

  .request_history {
    padding: 25px 10px;
  }

  .new_request {
    width: 143px;
    height: 30px;
    font-size: 14px;
  }
}
