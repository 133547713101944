.stats_table_container {
  width: 100%;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  /* box-shadow: 5px 4px 20px 0px rgba(203, 231, 249, 0.3); */
  margin-top: 20px;
}

@media screen and (min-width: 1000px) {
  .stats_table_container {
    overflow-y: auto;
  }
}

.stats_title_actions_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  padding-right: 32px;
  padding-top: 10px;
}

.stats_table_title {
  color: var(--neutral-neutral-01, #000);
  font-size: 20px;
  font-weight: 500;
  display: flex;
  gap: 20px;
  align-items: center;
  position: sticky;
  top: 0;
}

@media screen and (min-width: 1000px) {
  .stats_table_title {
    padding: 10px 10px 10px 16px;
  }
}

.stats_table_title img {
  width: 27.001px;
  height: 15.935px;
  cursor: pointer;
}

.table_nav_bar {
  padding: 10px;
  padding-left: 60px;
  background-color: white;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.row_mobile {
  display: none;
}

.row:nth-child(odd) {
  background-color: rgba(203, 231, 249, 0.2);
}

.table_header {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 8px 0;
  position: sticky;
  top: 0;
  padding-bottom: 15px;
}

.table_header div {
  padding: 0 16px;
  font-weight: 500;
  flex: 1;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell {
  flex: 1;
  padding: 0 16px;
  color: var(--neutral-neutral-01, #000);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell img {
  cursor: pointer;
}

.stats_title_actions_container_show {
  display: none;
}

@media screen and (max-width: 1000px) {
  .stats_table_container {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: calc(100% - 40px);
    padding: 20px;
  }

  .stats_title_actions_container {
    position: sticky;
    top: 0px;
    flex-wrap: wrap;
    padding-right: 0px;
    gap: 10px;
    width: calc(100% + 4px);
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
  }

  .stats_title_actions_container_hide {
    display: none;
  }
  .stats_title_actions_container_show {
    display: flex;
  }

  .table_header,
  .row {
    display: none;
  }

  .row_wrap {
    display: flex;
    /* background-color: rgba(203, 231, 249, 0.2); */
    border: 2px solid #dfe5f5;
    border-radius: 10px;
    width: 100%;
    min-height: 65px;
  }

  .row_mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    row-gap: 10px;
    padding: 10px 0px;
    flex-direction: column;
  }

  .cell {
    /* min-width: 70px; */
  }
  .table_nav_bar {
    padding-left: 0px;
    padding-right: 0px;
    background-color: white;
  }
}

.statsTable_title_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  width: 100%;
}

.table_checkbox {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
}

.cell_wrap_mobile {
  display: flex;
  gap: 5px;
  /* padding: 0 16px; */
  color: var(--neutral-neutral-01, #000);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cell_wrap_mobile div:nth-child(2) {
  text-align: right;
}

.cell_wrap_mobile div:nth-child(1) {
  text-align: left;
}

.action_cell_mobile {
  display: flex;
  justify-content: center;
}

.remove_outer_padding {
  padding: 0px;
  width: calc(100% - 5px);
}
