.admin_notes_content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  height: calc(100dvh - 105px);
  padding: 25px;
  gap: 20px;
  padding-bottom: 0px;
}

@media screen and (max-width: 700px) {
  .admin_notes_content {
    padding: 25px 10px;
    width: calc(100% - 20px);
  }
}

.notes_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
}

.notes_list_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #5981e980;
  max-width: 300px;
  gap: 10px;

  position: sticky;
  top: 0px;
  background-color: #fff;

  z-index: 2;
}

.notes_list_footer_button {
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  background-color: #5981e9;
  color: #fff;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
  cursor: pointer;

  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 2;
}

.notes_list_header .title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  color: #5981e9;

  align-self: flex-start;
  margin-left: 10px;
}

.notes_list_header .search {
  width: calc(100% - 8px);
}

.notes_list {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  /* height: calc(100% - 105px); */
  height: calc(100%);
  overflow-y: scroll;
  min-width: 250px;
  position: relative;
  border-radius: 10px 0px 0px 0px;
}

.notes_list::-webkit-scrollbar {
  width: 5px;
  background-color: #b1c0e680;
  color: #b1c0e680;
}

.notes_list::-webkit-scrollbar-track {
  background-color: #b1c0e680;
  border-radius: 5px;
}

.notes_list_card {
  max-width: 260px;
  padding: 20px;

  display: flex;
  gap: 13px;

  cursor: pointer;

  border-bottom: 1px solid #5981e980;
  cursor: pointer;
  background-color: #eef2ff;
}

.notes_list_card_active {
  background-color: #5981e980;
}

.notes_list_item {
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notes_list_item .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.notes_list_item .row .title {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;

  text-align: left;
  max-width: 160px;
}

.notes_list_card_active .row .title {
  color: #5981e9;
}

.notes_list_item .row .date {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
}

.notes_list_item .overview {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  height: 32px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.item_action {
  position: relative;
}

.item_action .delete_popup {
  width: 108px;
  height: 42px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border: 1px solid #5981e9;
  background-color: #fff;

  position: absolute;
  right: 0px;
  border-radius: 5px;
}

.delete_popup span {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;

  color: black;
}

.item_action_icon {
  cursor: pointer;
}

.text_editor_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.text_editor_container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  /* height: 50px !important; */
  padding: 20px;
  flex-wrap: wrap;
  row-gap: 10px;
}

.text_editor_container .header .title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  gap: 10px;
  align-items: center;
}

.text_editor_container .header .title .title_input {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  text-align: left;
}

.title img {
  display: none;
}

@media screen and (max-width: 700px) {
  .notes_list {
    width: 100%;
  }
  .notes_list_card {
    max-width: 100%;
  }
  .notes_list_header {
    max-width: 100%;
  }
  .text_editor_container {
    display: none;
  }
  .mobile_design {
    display: flex !important;
  }
  .mobile_design_hide {
    display: none !important;
  }
  .title img {
    display: block;
    cursor: pointer;
  }
}
