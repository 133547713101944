.tenant_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  /* box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06); */

  gap: 20px;
}

@media screen and (min-width: 1000px) {
  .tenant_details {
    overflow-y: scroll;
  }
}

@media screen and (max-width: 1000px) {
  .tenant_details {
    height: max-content;
  }
}

.details_header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--neutral-neutral-01, #000);
  font-size: 20px;
  font-weight: 500;
  justify-content: space-between;
  /* margin-bottom: 20px; */

  flex-wrap: wrap;
}

.detailed_section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details_header img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.paymentButtonGroup {
  display: flex;
  align-items: center;
  gap: 10px;

  flex-wrap: wrap;
}

@media screen and (max-width: 650px) {
  .paymentButtonGroup {
    width: 100%;
    justify-content: space-between;
  }
}

.documents_wrap {
  display: flex;
  flex-direction: column;
  /* gap: 25px; */
  width: 100%;
}

.content_wrap {
  display: flex;
  /* gap: 25px; */
  /* overflow-y: auto; */
  /* flex-wrap: wrap;
  justify-content: center; */
}

@media (max-width: 1000px) {
  .content_wrap {
    flex-direction: column;
  }
}
