body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  /* Thumb color */
  border: 1px solid rgba(178, 167, 244, 0.8);
  background: rgba(52, 192, 220, 0.2);
  /* Rounded corners for the thumb */
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Track color */
}
