.admin_approvals_content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  /* Header - 80px, Padding top - 25px */
  height: calc(100% - 105px);
  padding: 25px;
  gap: 20px;
  padding-bottom: 0px;
}

@media screen and (max-width: 700px) {
  .admin_approvals_content {
    padding: 25px 10px;
    width: calc(100% - 20px);
  }
}

.approvals_stats_table_actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  gap: 10px 40px;
  flex-wrap: wrap;
}

.action_buttons_wrapper {
  display: flex;
  gap: 5px;
}

.view_history_button {
  /* background-color: #fff; */
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 25px;
}

.view_history_button span {
  color: #fff;
  font-size: 14px;
  color: #000;
}

@media screen and (max-width: 420px) {
  .approvals_navbar {
    gap: 0px !important;
  }

  .approvals_navbar span {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 650px) {
  .approvals_navbar span {
    font-size: 14px !important;
  }
}
