.update_form {
  display: flex;
  flex-direction: column;
}

.profile_update_form {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin: 20px 10px;
}

@media screen and (max-width: 1000px) {
  .profile_update_form {
    gap: 20px;
    margin-top: 0px;
  }
}

@media screen and (max-width: 600px) {
  .profile_update_form {
    justify-content: center;
  }
}

.input_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  /* flex: 1; */
  min-width: 274px;
}

.input_name {
  color: var(--neutral-neutral-01, #000);
  font-size: 16px;
  font-weight: 400;
}

.field {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  border-radius: 10px;
  border: 1px solid #97ade7;
  background: rgba(89, 129, 233, 0.1);
  color: #5981e9;
  font-size: 16px;
  font-weight: 500;
  outline: none;
}

.field_error {
  border: 1px solid #ff0000;
  background: #fff0f0;
  color: #ff0000;
}

.input_field {
  /* min-width: 452px; */
  /* min-width: 240px; */
  height: 48px;
  min-width: 220px;
}

.error_message {
  color: #ff0000;
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  margin-top: -10px;
}

.input_field::placeholder {
  color: var(--Neutral-Neutral-01, #000);
  font-weight: 400;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::file-selector-button {
  display: none;
}

.upload_button {
  cursor: pointer;
  padding: 12px 20px;
  max-width: 220px;
}

.address_field {
  min-width: 452px;
  height: 164px;
}

.form_submit_button {
  width: 234px;
  height: 48px;
  border-radius: 3.636px;
  background: #5981e9;
  border: none;
  color: #fff;
  align-self: center;
}

@media (max-width: 600px) {
  .input_name {
    font-size: 14px;
  }

  .upload_button {
    padding: 10px;
  }
}
