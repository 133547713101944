.update_details {
  width: 187px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #5981e9;
  background: #5981e9;
  color: var(--Neutral-Neutral-02, #fff);
  font-family: Nunito;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: none;
}

@media (max-width: 1000px) {
  .update_details {
    width: 110px;
    min-width: max-content;
    padding: 0px 10px;
    /* height: 30px; */
    font-size: 14px;
  }
}
