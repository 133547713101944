.admin_earnings_plot_wrapper {
  /* min-width: 60%; */
  flex: 1;
  flex-shrink: 0;
  border-radius: 10px;
  /* margin-left: 10px; */
  background: var(--neutral-neutral-02, #fff);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.admin_earnings_plot_wrapper span {
  font-size: 20px;
  font-weight: 500;
}

.mark_area_container {
  display: flex;
  flex: 1;
  width: 100%;
}
.mark_area_plot {
  /* width: 1000px; */
  height: 100%;
  max-height: 326px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
