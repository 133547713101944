.home {
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 25px;
  padding-bottom: 0;
  height: calc(100% - 105px);
}

.content_wrap {
  display: flex;
  gap: 25px;
}

@media screen and (min-width: 700px) {
  .home {
    overflow-y: auto;
  }
  .content_wrap {
    overflow-y: auto;
  }
}

@media screen and (max-width: 700px) {
  .home {
    padding: 25px 10px;
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 1000px) {
  .home {
    /* padding: 10px; */
    align-items: center;
    /* justify-content: center; */
  }

  .content_wrap {
    flex-direction: column;
    width: 100%;
  }
}
