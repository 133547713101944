.back_drop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: fixed;
  z-index: 30;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* width: 40%; */
  gap: 20px;
  /* height: max-content; */
  padding: 25px;
  border-radius: 10px;
  background: var(--Neutral-Neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
}

.back_drop_navBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 7%;
}

.back_drop_navBar span {
  color: var(--Neutral-Neutral-01, #000);
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
}

.back_drop_navBar span:hover {
  cursor: pointer;
}

.active {
  border-bottom: 5px solid rgba(89, 129, 233, 0.8);
  padding-bottom: 5px;
}

.form_navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form_navbar span {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.modal_footer {
  color: rgba(0, 0, 0, 0.4);
  font-family: Nunito;
  font-size: 12px;
  font-weight: 500;
}

@media (max-width: 1000px) {
  .modal {
    /* width: calc(100% - 60px); */
    margin: 30px;
    max-height: 90%;
    gap: 15px;
  }

  .form_navbar {
    width: 100%;
    justify-content: space-between;
  }

  .form_navbar span {
    gap: 5px;
  }
}

.close_handler {
  cursor: pointer;
  background-color: #fff;
  color: #5981e9;
  font-size: large;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
}
