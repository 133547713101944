.select {
  display: flex;
  flex-direction: column;
  min-width: 240px;
  max-width: 240px;
  border-radius: 10px;
  border: 2px solid #97ade7;
  background: rgba(89, 129, 233, 0.1);
  color: #5981e9;
  font-size: 18px;
  font-weight: 500;
}

.select_inactive {
  padding: 0 20px;
  height: 48px;
  min-width: 281px;
  position: relative;
}

.select_active {
  padding: 0 20px;
  height: 48px;
  min-width: 281px;
  position: relative;
}
.select_active span {
  color: #5981e9;
}

.select input {
  width: 100%;
  border: none;
  outline: none;
  background: none;
  color: #5981e9;
  font-size: 20px;
  font-weight: 500;
}

.main_secion {
  display: flex;
  align-items: center;
  width: 280px;
  height: 50px;
  justify-content: space-between;
  border-radius: 10px;
  color: var(--Neutral-Neutral-01, #000);
  font-size: 16px;
  font-weight: 400;
}

.option_wrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 320px;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #5981e9;
  border-top: none;
  background: #fff;
  margin-top: 40px;
  margin-left: -20.5px;
  padding: 10px 0px;
  z-index: 1;
}

.option_wrap_inner {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 180px;
}

.option_wrap::-webkit-scrollbar {
  width: 5px;
  position: relative;
  left: 10px;
}

.option_wrap::-webkit-scrollbar-track {
  background: #fff;
}

.option_wrap::-webkit-scrollbar-thumb {
  background: #5981e9;
  border-radius: 10px;
}

.choices {
  display: flex;
  min-width: 215px;
  font-size: 16px;
  font-weight: 500;
  margin: 0px 10px;
  padding: 10px 10px;
  cursor: pointer;
}

.choices:hover {
  color: #fff;
  border-radius: 5px;
  background: #5981e9;
}

.select img {
  height: 10px;
}

.drop_down_icon_active {
  transform: rotate(180deg);
}

.placeholder {
  color: var(--Neutral-Neutral-01, #000);
  font-size: small;
}

@media (max-width: 1000px) {
  .select {
    min-width: 220px;
  }

  .select_inactive {
    max-width: 241px;
  }

  .main_secion {
    max-width: 241px;
  }

  .option_wrap {
    min-width: 280px;
  }
}
