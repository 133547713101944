.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content_wrap {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;

  gap: 20px;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  padding: 20px;
  padding-bottom: 40px;
  width: 450px;
}

.approval_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  width: 100%;
}

.margin_30 {
  margin-bottom: 30px;
}

.approval_icon {
  width: 121px;
  height: 121px;
}

.update_profile_wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.update_profile_checkbox {
  width: 20px;
  height: 20px;
  accent-color: #5981e9;
  background-color: #fff;
}

.cross {
  margin-left: 95%;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.decline_form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.form_label {
  color: var(--neutral-neutral-01, #000);
  font-size: 16px;
  font-weight: 500;
}

.form_input {
  /* width: 503px; */
  width: calc(100% - 42px);
  height: 171px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #5981e9;
  background: var(--neutral-neutral-02, #fff);
  outline: none;
  resize: none;
}

.modal_button {
  color: #fff;
  border-radius: 3.636px;
  border: 1px solid #5981e9;
  background: #5981e9;
  padding: 5px 20px;
  cursor: pointer;
}

.upload_button {
  cursor: pointer;
  padding: 12px 20px;
  max-width: 220px;
}

.field {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #5981e9;
  background: #fffaf8;
  color: #5981e9;
  font-size: 18px;
  font-weight: 500;
  outline: none;
}
