.admin_tenants_content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  /* Header - 80px, Padding top - 25px */
  height: calc(100% - 105px);
  padding: 25px;
  gap: 20px;
  padding-bottom: 0px;
  overflow-y: auto;
}

@media screen and (max-width: 700px) {
  .admin_tenants_content {
    padding: 25px 10px;
    width: calc(100% - 20px);
  }
}

.admin_tenants_content_bottomSpace {
  height: calc(100% - 200px);
}

.tenants_stats_table_actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  gap: 10px 40px;
  flex-wrap: wrap;
}

.rentStatusPaid,
.rentStatusPending {
  margin: 0px;
  /* font-size: larger; */
  font-weight: 600;
}

.rentStatusPaid {
  color: #00b428;
}

.rentStatusPending {
  color: #e80000;
}
