.stats_board {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
}

.stat_board_title {
  display: block;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.stats_board_stats_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.stat_board_single_stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
}

.stat_board_single_stat img {
  height: 30px;
  width: 30px;
}

.stat_board_single_stat_name {
  color: var(--neutral-neutral-01, #000);
  text-align: center;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
}

.stat_board_single_stat_value {
  color: var(--neutral-neutral-01, #000);
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 1000px) {
  .stats_board {
    width: calc(100% - 30px);
  }

  .stats_board_stats_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .stat_board_single_stat {
    width: 100px;
  }
}
