.table_wrap {
  width: calc(50% - 40px);
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  /* box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06); */
  padding: 20px;
}

.table_header_wrap {
  display: flex;
  width: calc(100% - 40px);
  height: 40px;
  border-radius: 10px;
  background: rgba(203, 231, 249, 0.3);
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.table_header {
  display: flex;
  gap: 10px;
  color: var(--neutral-neutral-01, #000);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
}

.table_content {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.table_data_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  height: 40px;
  padding: 0 20px;
}
.edit_table_content {
  row-gap: 10px;
}
@media screen and (max-width: 1000px) {
  .edit_table_data_wrap {
    flex-direction: column;
    align-items: normal;
    padding: 10px 20px;
    height: auto;
    row-gap: 5px;
  }
}

.table_content div:nth-child(odd) {
  border-radius: 10px;
  /* background: rgba(203, 231, 249, 0.2); */
}

.table_data_wrap span {
  display: flex;
  /* justify-content: flex-start; */
  /* min-width: 170px; */
  color: var(--neutral-neutral-01, #000);
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  word-break: break-word;
}

@media screen and (max-width: 650px) {
  .table_data_wrap span:nth-child(even) {
    /* color: var(--neutral-neutral-02, #fff); */
    text-align: right;
    display: block;
    justify-content: flex-end;
  }
}

.edit_input {
  min-width: 170px;
  border-radius: 5px;
  border: 1px solid #97ade7;
  background: rgba(89, 129, 233, 0.1);
  color: #5981e9;
  outline: none;
  font-size: 16px;
  padding: 8px 10px;
  font-weight: 400;
}

.edit_input::placeholder {
  color: var(--neutral-neutral-01, #000);
  font-weight: 400;
}

.edit {
  cursor: pointer;
}

.edit_input[type="file"] {
  max-width: 180px;
}

@media screen and (min-width: 1000px) {
  .edit_input[type="file"] {
    max-width: 232px;
  }
}

@media screen and (max-width: 1000px) {
  .table_wrap {
    width: calc(100% - 40px);
  }

  .table_data_wrap {
    width: 100%;
    padding: 0;
  }

  .table_data_wrap span {
    font-size: 14px;
    /* min-width: 120px; */
  }

  .edit_input {
    /* min-width: 100px; */
    /* font-size: 14px; */
  }
}

.fileInputWrapper {
  position: relative;
}

.fileInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.fileInputLabel {
  display: block;
  padding: 5px 10px;
  border: 1px solid #97ade7;
  color: #5981e9;
  border-radius: 5px;
  background: rgba(89, 129, 233, 0.1);
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
}
