.profile_wrap {
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 25px;
  padding-bottom: 0;
  height: calc(100% - 105px);
  overflow-y: auto;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 200px;
  gap: 10px;
  color: #5981e9;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
}

.profile img {
  width: 27.001px;
  height: 15.935px;
}

.change_password {
  cursor: pointer;
  min-width: 187px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #5981e9;
  background: #5981e9;
  color: var(--Neutral-Neutral-02, #fff);
  font-family: Nunito;
  font-size: 16px;
  font-weight: 500;
}

.profile_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: max-content;
  padding: 20px;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
  gap: 30px;
}

.profile_content_wrap {
  height: -webkit-fill-available;
  overflow-y: auto;
}

.card_wrap {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 50%;
}

.basic_details {
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.profile_img_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  /* background: var(--neutral-neutral-02, #ff0000); */
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
  position: relative;
}

.profile_img_container input {
  opacity: 0;
  width: 150px;
  height: 150px;
  cursor: pointer;
  position: absolute;
  z-index: 100;
}

.profile_img {
  width: 150px;
  height: 150px;
  border-radius: 2000px;
}

.upload_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #5981e9;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
  position: absolute;
  bottom: 0;
  right: 0;
}

.documents_wrap {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.content_wrap {
  display: flex;
  gap: 25px;
  /* overflow-y: auto; */
  /* flex-wrap: wrap;
  justify-content: center; */
}

@media (max-width: 1000px) {
  .basic_details {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }

  .profile {
    font-size: 16px;
  }

  .profile img {
    width: 20.335px;
    height: 12px;
  }

  .card_wrap {
    width: 100%;
  }

  .content_wrap {
    flex-direction: column;
  }

  .change_password {
    min-width: 143px;
    height: 30px;
    font-size: 14px;
  }
}
