.tenant_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100% - 50px);
  padding: 20px;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
  /* overflow-y: scroll; */
  gap: 20px;
}

.details_header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--neutral-neutral-01, #000);
  font-size: 20px;
  font-weight: 500;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}

.detailed_section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details_header img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.paymentButtonGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.history_table {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.message_card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #5981e980;
  width: calc(100% - 45px);
  align-items: flex-start;
  gap: 10px;
}

.message_card .message_header,
.message_card .message_footer {
  display: flex;
  gap: 10px;
}

.message_footer {
  align-self: flex-end;
}

.message_card .message_header .header_title {
  font-weight: 500;
}

.message_header .tenant_count,
.message_body .message_expander {
  color: #5981e9;
}

.tenant_count,
.message_expander {
  cursor: pointer;
}

.message_body .message_content {
  margin: 0px;
  text-align: start;
  font-size: 16px;
}

.message_expander {
  margin-left: 20px;
}

.schedule_CTA {
  display: flex;
  gap: 20px;
}

.schedule_CTA button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 30px;
  width: 100px;
  margin-top: 10px;
  /* margin-bottom: 15px; */
  cursor: pointer;
}

.cancel_button {
  color: #5981e9;
  background-color: white;
  padding: 8px;
  border: 1px solid #5981e9;
  outline: none;
  border-radius: 5px;
}
.reschedule_button {
  color: white;
  background-color: #5981e9;
  padding: 8px;
  border: 1px solid #5981e9;
  outline: none;
  border-radius: 5px;
}
.user_list_popup_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_list_popup {
  /* position: absolute; */
  width: 350px;
  /* height: 300px; */
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  /* z-index: 100; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.user_list_popup_table {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.user_list_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
}

.table_checkbox {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
}

.user_list_popup_footer {
  display: flex;
  gap: 10px;
}

.user_list_popup .close_handler {
  cursor: pointer;
  background-color: #fff;
  color: #5981e9;
  font-size: large;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  outline: none;
  border: none;
}
