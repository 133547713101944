.payment_form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.field {
  display: flex;
  align-items: center;
  /* gap: 20px; */
  padding: 0px 20px;
  border-radius: 10px;
  border: 2px solid #97ade7;
  background: rgba(89, 129, 233, 0.1);
  color: #5981e9;
  font-size: 14px;
  font-weight: 400;
  outline: none;
}

.field::placeholder {
  font-weight: 400;
}

.upload_button {
  max-width: 281px;
  cursor: pointer;
  padding: 16px 28px;
}

@media screen and (max-width: 1000px) {
  .upload_button {
    padding: 16px 20px;
  }
}

::file-selector-button {
  display: none;
}

.input_field {
  height: 48px;
  min-width: 281px;
}

.form_submit_button {
  width: 325px;
  height: 50px;
  border-radius: 10px;
  background: rgba(89, 129, 233, 0.8);
  border: none;
  color: var(--Neutral-Neutral-02, #fff);
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
}

.text_area {
  min-width: 300px;
  height: 245px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #97ade7;
  background: rgba(89, 129, 233, 0.1);
  resize: none;
}

::placeholder {
  color: var(--Neutral-Neutral-01, #000);
  font-family: Nunito;
  font-size: 14px;
  font-weight: 500;
}

.declined {
  border: 2px solid #e80000;
  background: rgba(232, 0, 0, 0.1);
  color: #e80000;
}

.approved {
  border: 2px solid #67a486;
  background: rgba(103, 164, 134, 0.1);
  color: #67a486;
}

.text_area_declined {
  /* width: 318px; */
  height: 130px;
  border: 2px solid #e80000;
  background: rgba(232, 0, 0, 0.1);
  color: #e80000;
}

@media (max-width: 1000px) {
  .payment_form {
    gap: 15px;
  }

  .form_submit_button {
    /* width: 100%; */
    width: 290px;
  }

  .input_field {
    min-width: 241px;
  }

  .upload_button {
    max-width: 241px;
  }

  .text_area {
    min-width: 261px;
  }
}
