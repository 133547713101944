.nav_bar_header {
  display: flex;
  gap: 50px;
  color: var(--neutral-neutral-01, #000);
  font-size: 16px;
  font-weight: 500;
}

.nav_bar_header:hover {
  cursor: pointer;
}

.active {
  border-bottom: 5px solid rgba(89, 129, 233, 0.8);
  padding-bottom: 5px;
}

@media screen and (max-width: 1000px) {
  .nav_bar_header {
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 420px) {
  .nav_bar_header {
    gap: 0px !important;
  }

  .nav_bar_header span {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 650px) {
  .nav_bar_header span {
    font-size: 14px !important;
  }
}
