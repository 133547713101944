.payments {
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 25px;
  padding-bottom: 0;
  height: calc(100% - 105px);
  /* overflow-y: auto; */
}

.payment_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: -webkit-fill-available;
  padding: 20px;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
  overflow-y: auto;
}

.payments_header {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
}

.download_invoice {
  display: flex;
  align-items: center;
  color: var(--Neutral-Neutral-01, #000);
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  gap: 10px;
}

@media screen and (max-width: 700px) {
  .payments {
    padding: 25px 10px;
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 1000px) {
  .payment_content {
    overflow-x: hidden;
  }

  .download_invoice {
    display: none;
  }
}

.units_stats_table_actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  gap: 10px 40px;

  flex-wrap: wrap;
}
.hideInDesktop {
  display: none;
}
@media screen and (max-width: 1000px) {
  .units_stats_table_actions {
    margin-top: -5px;
    margin-bottom: -30px;
    background-color: transparent;
  }
  .hideInDesktop {
    display: flex;
  }
}
