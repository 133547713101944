.details_card_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.title_wrap {
  display: flex;
  align-items: center;
  gap: 30px;
}

.card_title {
  display: flex;
  align-items: center;
  color: var(--Neutral-Neutral-01, #000);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  gap: 10px;
}

.home {
  max-width: 30px;
  height: 30px;
}

.table_content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}

.table_data_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  gap: 20px;
}

.table_data_wrap span {
  display: flex;
  justify-content: flex-start;
  /* min-width: 170px; */
  color: var(--neutral-neutral-01, #000);
  font-size: 16px;
  font-weight: 400;
  word-break: break-word;
}

.field_label {
  text-align: left;
}
.field_value {
  text-align: right;
}

.edit_input {
  /* min-width: 170px; */
  border-radius: 5px;
  border: 1px solid #97ade7;
  background: rgba(89, 129, 233, 0.1);
  color: #5981e9;
  outline: none;
  font-size: 18px;
  padding: 5px 10px;
}

.edit_input::placeholder {
  color: var(--neutral-neutral-01, #000);
  font-weight: 400;
}

.field_error {
  border: 2px solid #ff0000;
  background: #fff0f0;
  color: #ff0000;
}

.error_message {
  color: #ff0000 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin: 0px;
}

.edit {
  cursor: pointer;
}

@media (max-width: 1000px) {
  .details_card_wrap {
    justify-content: flex-start;
  }

  .table_data_wrap {
    gap: 10px;
  }

  .table_content {
    padding: 0;
  }

  .table_data_wrap span {
    /* min-width: 115px; */
    font-size: 14px;
  }

  .edit_input {
    min-width: 130px;
    font-size: 14px;
  }

  .table_data_wrap {
    width: 100%;
  }
}
