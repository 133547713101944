.App {
  text-align: center;
  height: 100dvh;
  border-radius: 10px;
  background-color: #f2f5fd;
}

@media screen and (max-width: 1000px) {
  .App {
    height: 100%;
    border-radius: 0px;
  }
}
