.expenses {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.expenses_header {
  display: flex;
  gap: 145px;
}

.expenses_header div {
  color: var(--neutral-neutral-01, #000);
  font-size: 18px;
  font-weight: 500;
  max-width: 150px;
}

.expenses_content {
  display: flex;
  gap: 125px;
}

.expenses_content div {
  display: flex;
  align-items: flex-start;
  color: var(--neutral-neutral-01, #000);
  font-size: 18px;
  font-weight: 500;
  min-width: 150px;
}

.input_label {
  display: flex;
  flex-direction: column;
}

.expenses_content input {
  border-radius: 10px;
  border: 1px solid #5981e9;
  background: #fffaf8;
  padding: 12px;
  outline: none;
}

.expenses_content input::placeholder {
  color: #ebe6e4;
  font-weight: 400;
}

.field_error {
  border: 1px solid #ff0000;
  background: #fff0f0;
  color: #ff0000;
}

.error_message {
  color: #ff0000;
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
}

.expenses span {
  min-width: 160px;
  color: var(--neutral-neutral-01, #000);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
