.tenants_invoice_warapper > * {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
  color-adjust: exact !important; /* Firefox 48 – 96 */
  print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
}

.tenants_invoice_warapper {
  /* background-color: #ffffff; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  /* display: none; */

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@media print {
  .tenants_invoice_warapper {
    display: flex;
  }
}

.tenants_invoice {
  background-color: #ffffff;
  height: min-content;
  /* overflow: hidden; */
  position: relative;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.rectangles {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
}
.rectangles img {
  width: 121px;
  position: relative;
  left: 30px;
}

.rectangle {
  width: 70%;
  height: 100px;
  background-color: #5981e9;
  position: absolute;
  top: 0;
  right: 0;
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%, 0 0, 0 0, 0 0);
  rotate: 180deg;
}
.rectangle2 {
  background-color: #5981e933;
  right: 20px;
}

.rectangle3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heading_textwrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  rotate: 180deg;
  color: #ffffff;
}
.heading_title {
  color: #ffffff;
  font-family: Nunito;
  font-size: 28px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
}
.heading_link {
  color: #ffffff;
  text-decoration: none;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
}

.mid_title_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  /* margin-bottom: 15px; */
}
.mid_title_container .title1 {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.mid_title_container .title2 {
}
.invoice_details {
  width: calc(100% - 40px);
  display: flex;
  padding: 20px;
}

.invoice_details > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;

  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.data_summary {
  background-color: #5981e9 !important;
}

.data_summary td:nth-child(1) {
  text-align: start;
}

.data_summary td {
  color: #ffffff;
  font-size: 16px !important;
}
.invoice_details .left_details {
  flex: 2;
}
.invoice_details .right_details {
  align-items: flex-end;
}
.invoice_seperator {
  width: calc(100vw - 20px);
  height: 2px;
  background-color: #5981e9;
  border-radius: 1px;
}

.invoice_body {
  width: 100%;
  display: flex;
  justify-content: center;
}
.invoice_data_table {
  width: calc(100% - 40px);
}
/* .invoice_data_table tr {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.invoice_data_table,
th,
td {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* font-size: 20px; */
}

.invoice_data_table {
  /* margin-bottom: 10px; */
  /* border-spacing: 0; */
  /* border-collapse: collapse; */
}

.invoice_data_table th {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: center;
}

.invoice_data_table td,
th {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px 0px;
}

/* alternate background color*/

.invoice_data_table tr:nth-child(even) {
  background-color: rgba(203, 231, 249, 0.2);
}

.invoice_data_table tr:nth-child(odd) {
  background-color: #ffffff;
}

.invoice_footer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  position: fixed;
  bottom: 0px;
  margin-top: 50px;
  display: none;
}
@media print {
  @page {
    size: A4;
    /* margin: 0; */
  }

  html,
  body {
    width: 210mm;
    /* height: calc(100% - 200px); */
    /* height: 100%; */
  }
  .invoice_footer {
    /* position: absolute; */
    position: fixed;
    display: block;

    /* page-break-before: always; */
  }
  .invoice_data_table tr:nth-child(21) {
    page-break-after: always;
  }
}

.invoice_footer p {
  margin: 0px;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #9a9595;
}

/* @media print {
  .rectangle {
    background-color: #5981e9;
  }
} */
